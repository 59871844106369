/* General styling for slick slides */
.custom-slider .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Initial state for all slides */
.custom-slider .slick-slide .custom-slide {
  transition: all 0.5s ease;
  transform: scale(0.85);
  filter: blur(4px);
  box-shadow: none;
  opacity: 0.7;
}

/* Styling for the focused slide */
.custom-slider .slick-center .custom-slide {
  transform: scale(1.1); /* Increase the scale for focused slide */
  filter: blur(0);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

/* Additional styling for the custom slide */
.custom-slider .custom-slide {
  transform: scale(0.95);
  filter: blur(4px);
  box-shadow: none;
}

/* Image container and image styles */
.custom-slide .image-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align the image at the bottom */
  height: 100%; /* Make sure the container height fills the card */
}

/* Image styles for the default blurred state */
.custom-slide .image-container img {
  transition: all 0.5s ease;
  height: 50%; /* 50% height when blurred */
  object-fit: cover;
  object-position: top; /* Crop the bottom part of the image */
}

/* Image styles when the slide is focused */
.custom-slider .slick-center .image-container img {
  height: 90%; /* 90% height when focused */
  object-fit: cover;
  object-position: center; /* Center the image when focused */
}